const chartColors = [
  '#377eb8',
  '#fd1e29',
  '#66a61d',
  '#984ea3',
  '#1fd2d5',
  '#fd7f00',
  '#af8d01',
  '#7f80cd',
  '#b3e900',
  '#c42e60',
  '#a65628',
  '#f781bf',
  '#8dd3c7',
  '#bebbda',
  '#fb8072',
  '#80b1d3',
  '#fdb562',
  '#fccde5',
  '#164A03',
  '#ffed6f',
];

export default chartColors;
