/* This Factory exposes data transformers/wrappers to pack API data to Apexchart understandable formats */

import { sumBy } from './Utils';

export function noWrap(data, wrapper_opts, t) {
  const { data_key } = wrapper_opts;
  return focusData(data, data_key);
}

export const disparityWrapper = (data, { data_key, excluded_cols }, t) => {
  const tableData = focusData(data, data_key);
  const Total = sumBy(tableData, 'total_quantity');

  const hasDisparity = (total, count, key) => {
    const totalByKey = sumBy(tableData, key);
    const a = 0.25;
    const sigma = 0.05;

    const rate = totalByKey / Total;
    const margin = rate * a + sigma;

    return {
      bigger: count / total > rate + margin,
      lower: count / total < rate - margin,
      left: count / total,
      right: totalByKey / Total,
    };
  };

  tableData.forEach((row) => {
    row.classes = {};
    const total = row.total_quantity;
    Object.keys(row)
      .filter((col) => ![...excluded_cols, 'classes'].includes(col))
      .forEach((col) => {
        const dispa = hasDisparity(total, row[col], col);
        if (dispa.bigger || dispa.lower) {
          row.classes[col] = {
            fontWeight: 800,
            color: dispa.lower ? 'red' : 'limegreen',
          };
        }
      });
  });
  return tableData;
};

export function agingWrapper(data, wrapper_opts, t) {
  const { translation_namespace, data_key } = wrapper_opts;

  const raw_data = focusData(data, data_key);
  const date_keys = Object.keys(raw_data);
  const command_states = [];

  date_keys.forEach(function (date) {
    Object.keys(raw_data[date]).forEach(function (status) {
      if (command_states.indexOf(status) === -1) {
        command_states.push(status);
      }
    });
  });

  const series = command_states.map(function (state) {
    return {
      name: t(translation_namespace + state),
      data: date_keys.map((date) => raw_data[date][state] || 0),
      key: state,
    };
  });

  return { series: series, dates: date_keys };
}

export function categoryWrapper(data, wrapper_opts, t) {
  const { x, y, data_key } = wrapper_opts;

  return focusData(data, data_key).map((item) => ({ x: item[x], y: item[y] }));
}

export function categoryWithLabelsWrapper(data, wrapper_opts, t) {
  const { label, value, data_key } = wrapper_opts;

  const labels = [];
  const values = [];

  focusData(data, data_key).forEach((item) => {
    labels.push(item[label]);
    values.push(item[value]);
  });

  return [{ labels: labels, values: values }];
}

export function datetimeWrapper(data, wrapper_opts, t) {
  const { x, y, data_key } = wrapper_opts;

  return focusData(data, data_key).map((item) => [item[x], item[y]]);
}

/* {series: [{name:'blabla',data:[bdat1,bdat2],key:'bla'},{name:'oblobl',data:[odat1,odat2],key:'obl'}], dates: [date1,date2]}*/
export function multiDateTimeWrapper(data, wrapper_opts, t) {
  const { data_key, excluded_cols, x } = wrapper_opts;

  const datum = focusData(data, data_key);
  const keys = Object.keys(datum[0]).filter(
    (key) => !excluded_cols.includes(key) && key !== x
  );
  const result = {
    series: keys.map((key) => ({ name: key, data: [], key: key })),
    dates: [],
  };
  datum.forEach((dataLine) => {
    result.series.forEach((serie) => {
      serie.data.push(dataLine[serie.key]);
    });
    result.dates.push(dataLine[x]);
  });
  return result;
}

export function simpleArrayWrapper(data, wrapper_opts, t) {
  const { key, data_key } = wrapper_opts;

  return focusData(data, data_key).map((item) => item[key]);
}

export function evoWrapper(data, wrapper_opts, t) {
  const { x, y, evo_key, data_key } = wrapper_opts;
  return {
    data: focusData(data, data_key).map((item) => [item[x], item[y]]),
    evo: focusData(data, evo_key) || {},
  };
}

export function focusData(data, key) {
  if (key) {
    if (key instanceof Array) {
      return key.reduce((dat, k) => dat[k], data);
    }
    return data[key];
  }
  return data;
}
