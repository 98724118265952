import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';

moment.locale(
  (supported(navigator.language) || userLanguage() || 'en-US').substring(0, 2)
);

function userLanguage() {
  if (navigator.languages) {
    return navigator.languages.find((lng) => {
      return supported(lng);
    });
  }
}

function supported(lng) {
  if (lng) {
    return ['en', 'fr', 'es'].find((val) => {
      return lng.substr(0, val.length) === val;
    });
  }
}

const LanguageDetector = {
  type: 'languageDetector',
  detect: () => supported(navigator.language) || userLanguage() || 'en-US',
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en-US',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
