import React from 'react';
import DatetimeChart from '../SBICharts/DatetimeChart';
import CategoryChart from '../SBICharts/CategoryChart';
import MultiSeriesDatetimeChart from '../SBICharts/MultiSeriesDatetimeChart';
import MultiDatetimeChart from '../SBICharts/MultiDatetimeChart';
import DonutChart from '../SBICharts/DonutChart';

/*
 Required props as : 
 {
 "start":date,
 "end":date,
 "xtype":'datetime',
 "title":'translated title',
 "subtitle":'translated subtitle',
 "labels":{
 "index":"index label",
 "value":"value label",
 },
 "api":{
 "module": "OMS/analytics",
 "path": "api/endpoint/v1",
 "data_wrapper": function,
 "queryparams":"test=blabla"
 },
 'type':'line',
 'orientation':'vertical',
 'height':400
 }

 */
export default function ChartCard(props) {
  const { data } = props;

  function onChange(indexes) {
    if (props.onChange) {
      if (props.xtype === 'percent') {
        props.onChange(indexes.map((index) => data[0].labels[index]));
      } else {
        props.onChange(indexes.map((index) => data[index]));
      }
    }
  }

  function chartIsInteractive() {
    return !!props.onChange;
  }

  function chartComponentSwitch() {
    switch (props.xtype) {
      case 'datetime':
        return (
          <DatetimeChart
            {...props}
            data={data}
            onChange={onChange}
            interactive={chartIsInteractive()}
          />
        );
      case 'multiseriesdatetime':
        return (
          <MultiSeriesDatetimeChart
            {...props}
            data={data}
            onChange={onChange}
            interactive={chartIsInteractive()}
          />
        );
      case 'multidatetime':
        return (
          <MultiDatetimeChart
            {...props}
            data={data}
            onChange={onChange}
            interactive={chartIsInteractive()}
          />
        );
      case 'category':
        return (
          <CategoryChart
            {...props}
            data={data}
            onChange={onChange}
            interactive={chartIsInteractive()}
          />
        );
      case 'percent':
        return (
          <DonutChart
            {...props}
            data={data}
            onChange={onChange}
            interactive={chartIsInteractive()}
          />
        );
      default:
        return props.xtype + ' is not recognized in ChartCard';
    }
  }

  return (
    <span>
      <div className="card-msg">{props.message}</div>
      {chartComponentSwitch()}
    </span>
  );
}
