import React from 'react';
import SummaryCard from '../../ContentCard/SummaryCard';
import ChartCard from '../../ContentCard/ChartCard';
import ContentCard from '../../ContentCard/ContentCard';
import { useTranslation } from 'react-i18next';
import { agingWrapper, noWrap } from '../../../DataTransformFactory';
import moment from 'moment';
import { OMSLink } from '../../../API';

export default function Dashboard(props) {
  const { t } = useTranslation('translation');

  const stats_of_day = {
    title: t('views.dashboard.daystats'),
    namespace: 'orders_live_overview',
    api: {
      module: 'OMS',
      path: 'orders/live_overview',
      queryparams: {
        'order[pending_order_max_age_in_days]': 200,
      },
      data_wrapper_opts: {
        data_key: ['data', 'orders_live_overview'],
      },
      data_wrapper: noWrap,
    },
    height: 130,
    translation_domain: 'views.dashboard.summary',
    keys: ['created_today', 'shipped_today'],
    custom_colors: {
      created_today: '#000000',
      shipped_today: '#5bda05',
    },
    links: {
      created_today: OMSLink(
        '#/orders?search={"origin_created_at__gt":["' +
          moment().startOf('day').format('YYYY-MM-DD HH:mm') +
          '"]}'
      ),
      shipped_today: OMSLink('#/orders?search={"state__eq"%3A["shipped"]}'),
    },
    component: SummaryCard,
  };

  const current_status = {
    title: t('views.dashboard.evolution'),
    namespace: 'orders_live_overview',
    api: {
      module: 'OMS',
      path: 'orders/live_overview',
      queryparams: {
        'order[pending_order_max_age_in_days]': 200,
      },
      data_wrapper_opts: {
        data_key: ['data', 'orders_live_overview'],
      },
      data_wrapper: noWrap,
    },
    height: 130,
    translation_domain: 'views.dashboard.summary',
    keys: [
      'in_trouble',
      'waiting_for_stock',
      'dispatched',
      'to_be_prepared',
      'in_preparation',
    ],
    custom_colors: {
      to_be_prepared: '#49A8C2',
      waiting_for_stock: '#FFC105',
      dispatched: '#000000',
      in_trouble: '#DC3545',
      in_preparation: '#7c51d1',
    },
    links: {
      to_be_prepared: OMSLink(
        '#/orders?search={"state__eq"%3A["to_be_prepared"]}'
      ),
      waiting_for_stock: OMSLink(
        '#/orders?search={"state__eq"%3A["waiting_for_stock"]}'
      ),
      dispatched: OMSLink('#/orders?search=%7B"state__eq"%3A["dispatched"]}'),
      in_trouble: OMSLink('#/orders?search={"state__eq"%3A["in_trouble"]}'),
      in_preparation: OMSLink(
        '#/orders?search={"state__eq"%3A["in_preparation"]}'
      ),
    },
    component: SummaryCard,
  };

  const cmds_aging = {
    xtype: 'multiseriesdatetime',
    title: t('views.dashboard.orders_aging'),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.orders'),
    },
    api: {
      module: 'OMS',
      path: 'orders/pending_since',
      queryparams: {
        'order[pending_order_max_age_in_days]': 30,
      },
      data_wrapper_opts: {
        translation_namespace: 'views.dashboard.summary.',
        data_key: ['data', 'pending_since'],
      },
      data_wrapper: agingWrapper,
    },
    custom_colors: {
      // Override default colors for each provided series key
      to_be_prepared: '#70daf2',
      waiting_for_stock: '#fb9e32',
      in_trouble: '#ff4b4b',
      shipped_today: '#5bda05',
    },
    type: 'bar',
    orientation: 'vertical',
    height: 400,
    component: ChartCard,
  };

  return [
    <div className="column is-full" key="stats_of_day">
      <ContentCard {...props} {...stats_of_day} />
    </div>,
    <div className="column is-full" key="current_status">
      <ContentCard {...props} {...current_status} />
    </div>,
    <div className="column is-full" key="cmds_aging">
      <ContentCard {...props} {...cmds_aging} />
    </div>,
  ];
}
