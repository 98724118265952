import React from 'react';
import ContentCard from '../../ContentCard/ContentCard';
import TableCard from '../../ContentCard/TableCard';
import ChartCard from '../../ContentCard/ChartCard';
import StatsCard from '../../ContentCard/StatsCard';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  categoryWithLabelsWrapper,
  categoryWrapper,
  disparityWrapper,
  noWrap,
  simpleArrayWrapper,
} from '../../../DataTransformFactory';
import Formatter from '../../../FormatFactory';
import Reducers from '../../../ReducerFactory';

export function formatLabel(orderSources, label) {
  const source = orderSources.find((os) => os.name === label);
  return source?.display_name || label;
}

export function getBestSource(dataline, actualColumns, orderSources) {
  const sourceColumns = Object.keys(dataline).filter(
    (dataKey) => !actualColumns.includes(dataKey)
  );
  const bestSource = sourceColumns.reduce((best, sourceCol) => {
    const sourceValue = dataline[sourceCol];
    if (!best || sourceValue > best.value) {
      return { src: sourceCol, value: sourceValue };
    }
    return best;
  }, null);

  const totalValue = dataline['total_quantity'];

  const bestRatio = Math.round((bestSource.value / totalValue) * 100);

  return [
    <div key={'bestSrc' + dataline.product_ref}>
      {formatLabel(orderSources, bestSource.src)}
    </div>,
    <div key={'bestSrcValue' + dataline.product_ref}>
      {bestSource.value} ({bestRatio}%)
    </div>,
  ];
}

export default function Offers(props) {
  const { t } = useTranslation('translation');
  const history = useHistory();

  function onSourceChangeBar(selection) {
    history.push('/offers/source/' + selection[0].x);
  }

  function onSourceChangeDonut(selection) {
    history.push('/offers/source/' + selection[0]);
  }

  const offer_stats = {
    title: t('views.offers.totals'),
    items: [
      {
        title: 'views.offers.total',
        reducer: Reducers.total,
        formatter: Formatter.number,
      },
    ],
    api: {
      module: 'analytics',
      path: 'best_selling_offers/table/v2',
      data_wrapper_opts: { key: 'total_quantity', data_key: 'data' },
      data_wrapper: simpleArrayWrapper,
    },
    height: 120,
    component: StatsCard,
  };

  const best_selling_offers = {
    title: t('views.offers.overview'),
    columns: [
      {
        key: 'product_ref',
        label: t('views.offers.cols.product_ref'),
      },
      { key: 'latest_title', label: t('views.offers.cols.offer_title') },
      {
        key: 'total_quantity',
        label: t('views.offers.cols.total_quantity'),
        formatter: Formatter.number,
      },
      {
        key: 'bestSource',
        computeFct: getBestSource,
        label: t('views.offers.cols.best_source'),
        disableSort: true,
      },
    ],
    id_key: 'product_ref',
    api: {
      module: 'analytics',
      path: 'offers_orderings/full_table/v2/detailed',
      data_wrapper_opts: {
        data_key: 'data',
      },
      data_wrapper: noWrap,
    },
    link: {
      route: '/offers/',
    },
    component: TableCard,
  };
  const best_selling_offers_detailed = {
    title: t('views.offers.detailed'),
    columns: [
      {
        key: 'product_ref',
        label: t('views.offers.cols.product_ref'),
      },
      { key: 'latest_title', label: t('views.offers.cols.offer_title') },
      {
        key: 'total_quantity',
        label: t('views.offers.cols.total_quantity'),
        formatter: Formatter.number,
      },
    ],
    include_all_raw_cols: true,
    sticky_cols: 3,
    id_key: 'product_ref',
    api: {
      module: 'analytics',
      path: 'offers_orderings/full_table/v2/detailed',
      data_wrapper_opts: {
        data_key: 'data',
        excluded_cols: ['product_ref', 'latest_title', 'total_quantity'],
      },
      data_wrapper: disparityWrapper,
    },
    link: {
      route: '/offers/',
    },
    component: TableCard,
  };

  const best_selling_offers_multi = {
    title: t('views.offers.offers'),
    defs: [best_selling_offers, best_selling_offers_detailed],
  };

  const offers_per_source = {
    xtype: 'category',
    title: t('views.offers.source_offer_selling_figures'),
    message: t('views.offers.source_proportion_msg'),
    labels: {
      index: t('charts.units.source'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    api: {
      module: 'analytics',
      path: 'offers_distribution/by_source/v1',
      data_wrapper_opts: {
        x: 'source',
        y: 'total_quantity',
        data_key: ['distribution', 'data'],
      },
      data_wrapper: categoryWrapper,
    },
    type: 'bar',
    onChange: onSourceChangeBar,
    orientation: 'horizontal',
    height: 400,
    component: ChartCard,
  };

  const offers_per_source_percent = {
    xtype: 'percent',
    title: t('views.offers.source_offer_selling_figures'),
    message: t('views.offers.source_proportion_msg'),
    labels: {
      index: t('charts.units.source'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    api: {
      module: 'analytics',
      path: 'offers_distribution/by_source/v1',
      data_wrapper_opts: {
        label: 'source',
        value: 'total_quantity',
        data_key: ['distribution', 'data'],
      },
      data_wrapper: categoryWithLabelsWrapper,
    },
    onChange: onSourceChangeDonut,
    orientation: 'horizontal',
    height: 400,
    component: ChartCard,
  };

  return [
    <div className="column is-full" key="offer_stats">
      <ContentCard {...props} {...offer_stats} />
    </div>,
    <div className="column is-full is-half-widescreen" key="offers_per_source">
      <ContentCard {...props} {...offers_per_source} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="offers_per_source_percent"
    >
      <ContentCard {...props} {...offers_per_source_percent} />
    </div>,
    <div className="column is-full" key="best_selling_offers">
      <ContentCard {...props} {...best_selling_offers_multi} multi />
    </div>,
    /*
     <div className="column is-full" key="best_selling_offers">
     <ContentCard {...props} {...best_selling_offers} />
     </div>,
     <div className="column is-full" key="best_selling_offers_detailed">
     <ContentCard {...props} {...best_selling_offers_detailed} />
     </div>,*/
  ];
}
