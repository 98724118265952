import React from 'react';
import ChartCard from '../../ContentCard/ChartCard';
import StatsCard from '../../ContentCard/StatsCard';
import ContentCard from '../../ContentCard/ContentCard';
import { useTranslation } from 'react-i18next';
import {
  categoryWithLabelsWrapper,
  categoryWrapper,
  datetimeWrapper,
  simpleArrayWrapper,
  multiDateTimeWrapper,
} from '../../../DataTransformFactory';
import Formatter from '../../../FormatFactory';
import Reducers from '../../../ReducerFactory';
import { useFilter } from '../../../providers';

export default function Orders(props) {
  const { t } = useTranslation('translation');
  const { filter } = useFilter();

  let order_stats_items = [
    {
      title: 'views.orders.total_received',
      key: 'received',
      reducer: Reducers.total,
      formatter: Formatter.noFormat,
      color: '#ff6c00',
    },
    {
      title: 'views.orders.average_received',
      key: 'received',
      reducer: Reducers.avg,
      formatter: Formatter.round,
      color: '#ff6c00',
    },
    {
      title: 'views.orders.total_sent',
      key: 'sent',
      reducer: Reducers.total,
      formatter: Formatter.noFormat,
      color: '#33ccff',
    },
    {
      title: 'views.orders.average_sent',
      key: 'sent',
      reducer: Reducers.avg,
      formatter: Formatter.round,
      color: '#33ccff',
    },
  ];

  if (filter.singleDay) {
    order_stats_items = [
      {
        title: 'views.orders.total_received',
        key: 'received',
        reducer: Reducers.total,
        formatter: Formatter.noFormat,
        color: '#ff6c00',
      },
      {
        title: 'views.orders.total_sent',
        key: 'sent',
        reducer: Reducers.total,
        formatter: Formatter.noFormat,
        color: '#33ccff',
      },
    ];
  }

  const order_stats = {
    title: t('views.orders.total'),
    items: order_stats_items,
    api: {
      module: 'analytics',
      path: 'daily_orders_volume/by_source/v1',
      data_wrapper_opts: {
        key: 'volume',
        data_key: ['daily_values', 'data'],
      },
      data_wrapper: simpleArrayWrapper,
    },
    alias: 'received',
    mergeData: [
      {
        api: {
          module: 'analytics',
          path: 'daily_order_shipments_volume/by_source/v1',
          data_wrapper: simpleArrayWrapper,
          data_wrapper_opts: {
            key: 'volume',
            data_key: ['daily_values', 'data'],
          },
        },
        alias: 'sent',
      },
    ],
    height: 120,
    component: StatsCard,
  };

  const daily_orders = {
    xtype: 'multidatetime',
    title: t('views.orders.totals'),
    series: ['sent', 'received'],
    custom_colors: {
      sent: '#33ccff',
      received: '#ff6c00',
    },
    chart_types: {
      sent: 'bar',
      received: 'line',
    },
    api: {
      module: 'analytics',
      path: 'daily_orders_volume/by_source/v1',
      data_wrapper_opts: {
        x: 'date',
        y: 'volume',
        data_key: ['daily_values', 'data'],
      },
      data_wrapper: datetimeWrapper,
    },
    alias: 'received',
    mergeData: [
      {
        api: {
          module: 'analytics',
          path: 'daily_order_shipments_volume/by_source/v1',
          data_wrapper_opts: {
            x: 'date',
            y: 'volume',
            data_key: ['daily_values', 'data'],
          },
          data_wrapper: datetimeWrapper,
        },
        alias: 'sent',
      },
    ],
    height: 400,
    component: ChartCard,
  };

  const daily_orders_by_source_received = {
    xtype: 'multiseriesdatetime',
    title: t('views.orders.orders_received_figures'),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    api: {
      module: 'analytics',
      path: 'daily_orders_volume/by_source/v1',
      data_wrapper_opts: {
        x: 'date',
        data_key: ['daily_values', 'data'],
        excluded_cols: ['index', 'volume'],
      },
      data_wrapper: multiDateTimeWrapper,
    },
    stacked: false,
    type: 'line',
    orientation: 'vertical',
    height: 400,
    component: ChartCard,
  };

  const daily_orders_by_source_sent = {
    xtype: 'multiseriesdatetime',
    title: t('views.orders.orders_sent_figures'),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    api: {
      module: 'analytics',
      path: 'daily_order_shipments_volume/by_source/v1',
      data_wrapper_opts: {
        x: 'date',
        data_key: ['daily_values', 'data'],
        excluded_cols: ['index', 'volume'],
      },
      data_wrapper: multiDateTimeWrapper,
    },
    stacked: false,
    type: 'line',
    orientation: 'vertical',
    height: 400,
    component: ChartCard,
  };

  const daily_orders_by_source = {
    title: t('views.orders.orders_by_source'),
    defs: [daily_orders_by_source_received, daily_orders_by_source_sent],
  };

  const orders_per_source = {
    xtype: 'category',
    title: t('views.orders.source_order_figures'),
    labels: {
      index: t('charts.units.source'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    api: {
      module: 'analytics',
      path: 'orders_distribution/by_source/v2',
      data_wrapper_opts: {
        x: 'source',
        y: 'order_count',
        data_key: 'data',
      },
      data_wrapper: categoryWrapper,
    },
    type: 'bar',
    orientation: 'horizontal',
    height: 400,
    component: ChartCard,
  };

  const orders_per_source_percent = {
    xtype: 'percent',
    title: t('views.orders.source_order_figures'),
    labels: {
      index: t('charts.units.source'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    api: {
      module: 'analytics',
      path: 'orders_distribution/by_source/v2',
      data_wrapper_opts: {
        label: 'source',
        value: 'order_count',
        data_key: 'data',
      },
      data_wrapper: categoryWithLabelsWrapper,
    },
    height: 400,
    component: ChartCard,
  };

  return [
    <div className="column is-full" key="order_stats">
      <ContentCard {...props} {...order_stats} />
    </div>,
    <span className="column is-full" key="multi-days-cards">
      {filter.singleDay
        ? null
        : [
            <div className="column is-full" key="daily_orders">
              <ContentCard {...props} {...daily_orders} />
            </div>,
            <div className="column is-full" key="daily_orders_by_source">
              <ContentCard {...props} {...daily_orders_by_source} multi />
            </div>,
          ]}
    </span>,
    <div className="column is-full is-half-widescreen" key="orders_per_source">
      <ContentCard {...props} {...orders_per_source} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="orders_per_source_percent"
    >
      <ContentCard {...props} {...orders_per_source_percent} />
    </div>,
  ];
}
