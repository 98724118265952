import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import menuItems from './menuItems.js';
import { useUser } from './providers';

function NavbarItem(props) {
  const { t } = useTranslation('translation');
  const location = useLocation();
  const history = useHistory();

  return (
    <div
      data-testid={`navbarItem.${props.item.label}`}
      className={
        location.pathname.indexOf(props.item.path) !== -1
          ? 'navbar-item active-navbar-item'
          : 'navbar-item'
      }
      onClick={() => {
        history.push(props.item.path);
        props.togglenav();
      }}
      key={props.item.path}
    >
      <img src={props.item.icon} alt={t(props.item.label)} />
      {t(props.item.label)}
    </div>
  );
}

export default function Navbar(props) {
  const [state, setState] = useState({
    isNavbarOpen: false,
    isMenuOpen: true,
  });
  const user = useUser();
  const { t } = useTranslation();
  const location = useLocation();

  const toggleNav = function () {
    setState((state) => ({
      isNavbarOpen: !state.isNavbarOpen,
      isMenuOpen: state.isMenuOpen,
    }));
  };

  const toggleMenu = function () {
    setState((state) => ({
      isNavbarOpen: state.isNavbarOpen,
      isMenuOpen: !state.isMenuOpen,
    }));
    props.togglemenu();
  };

  function pageName() {
    const page_path = menuItems.filter(
      (item) => location.pathname.indexOf(item.path) !== -1
    )[0];
    if (page_path !== undefined) {
      return t(
        menuItems.filter(
          (item) => location.pathname.indexOf(item.path) !== -1
        )[0].label
      );
    }
    return null;
  }

  return (
    <nav
      className="navbar"
      role="navigation"
      aria-label="main navigation"
      data-testid="navbar"
    >
      <div className="navbar-brand">
        <button
          className={
            state.isNavbarOpen
              ? 'navbar-burger is-active is-hidden-tablet'
              : 'navbar-burger is-hidden-tablet'
          }
          onClick={toggleNav}
          aria-label="nav-toggle"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
        <button
          className={
            state.isMenuOpen
              ? 'navbar-burger is-active is-hidden-mobile is-visible-desktop'
              : 'navbar-burger is-hidden-mobile is-visible-desktop'
          }
          onClick={toggleMenu}
          aria-label="menu-toggle"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
        <a className="navbar-item is-hidden-mobile" href="/">
          <img
            src="/assets/logos/logos-sbo-analytics-logo.svg"
            alt="logo"
            width="112"
            height="28"
          />
        </a>
        <a className="navbar-item is-hidden-tablet mobile-logo" href="/">
          <img
            src="/assets/logos/logos-sbo-analytics-logo.svg"
            alt="logo"
            width="112"
            height="28"
          />
        </a>
        <div className="navbar-item vertical-navbar-separator is-hidden-mobile"></div>
        <div
          className="navbar-item is-hidden-mobile"
          aria-label="current-page-title"
        >
          {pageName()}
        </div>
        <div className="navbar-item align-end is-hidden-mobile">
          <a href={process.env.REACT_APP_OMS_SERVER + 'users/sign_out'}>
            <img
              className="icon-16"
              alt={t('menu.logout')}
              src="/assets/icons/power-on-off.svg"
            />
          </a>
        </div>
      </div>

      <div
        className={
          state.isNavbarOpen
            ? 'navbar-menu is-active is-hidden-tablet'
            : 'navbar-menu is-hidden-tablet'
        }
      >
        <div className="navbar-start">
          {menuItems
            .filter((i) => (i.hidden ? !i.hidden(user) : true))
            .map((item) => (
              <NavbarItem item={item} key={item.path} togglenav={toggleNav} />
            ))}
        </div>
        <div className="navbar-separator"></div>
        <div className="navbar-end">
          <a
            className="navbar-item"
            href={process.env.REACT_APP_OMS_SERVER + 'users/sign_out'}
          >
            <img
              className="icon-16"
              alt={t('menu.logout')}
              src="/assets/icons/power-on-off.svg"
            />
            {t('menu.logout')}
          </a>
        </div>
      </div>
    </nav>
  );
}
