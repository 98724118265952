import React, { useCallback, useEffect, useState } from 'react';

import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import fr from 'apexcharts/dist/locales/fr.json';
import en from 'apexcharts/dist/locales/en.json';
import es from 'apexcharts/dist/locales/es.json';

import sanitizeSeries from '../../sanitizeSeries';

import chartColors from './chartColors.js';

import Helpers from './SBIChartHelpers';
import { useData } from '../../providers';

export default function DonutChart(props) {
  const { i18n } = useTranslation('translation');
  const { orderSources } = useData();

  const formatLabel = useCallback(
    (labels) => {
      /*istanbul ignore if*/
      if (props.disableLabelFormatter) {
        return labels;
      }
      return labels?.map((label) => {
        const source = orderSources.find((os) => os.name === label);
        return source?.display_name || label;
      });
    },
    [orderSources, props.disableLabelFormatter]
  );

  function getAndSetSelection(evt, context, conf) {
    if (props.onChange) {
      const selectedIndexes = conf.selectedDataPoints;
      props.onChange(selectedIndexes);
    }
  }

  function interactivityStyler(event) {
    if (props.interactive) {
      event.srcElement.style.cursor = 'pointer';
    } else {
      event.srcElement.style.cursor = 'initial';
    }
  }

  const [state, setState] = useState({
    options: {
      chart: {
        id: props.title,
        zoom: {
          enabled: false,
        },
        events: {
          dataPointSelection: getAndSetSelection,
          dataPointMouseEnter: interactivityStyler,
        },
        locales: [fr, en, es],
        defaultLocale: i18n.language.substring(0, 2),

        toolbar: {
          show: true,
          tools: {
            download: "<img src='/assets/icons/download.svg' width='16px' />",
          },
          export: {
            csv: {
              columnDelimiter: Helpers.csvSeparator(i18n.language),
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      labels: sanitizeSeries(props.data[0].labels),
      colors: chartColors,
    },
    series: sanitizeSeries(props.data[0].values),
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        labels: formatLabel(prev.options.labels),
      },
    }));
  }, [formatLabel]);

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="donut"
      width="100%"
      height={props.height}
    />
  );
}
