import React from 'react';
import ContentCard from '../../ContentCard/ContentCard';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../providers';
import Loader from '../../Loader/Loader';
import Formatter from '../../../FormatFactory';
import TableCard from '../../ContentCard/TableCard';
import ChartCard from '../../ContentCard/ChartCard';
import { categoryWrapper, noWrap } from '../../../DataTransformFactory';

export default function Shipping(props) {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const { canUseOmsBackend } = useUser();

  if (canUseOmsBackend === undefined) {
    // Try again later when user is loaded
    return <Loader height={500} />;
  }

  const commonColumns = [
    { key: 'carrier_name', label: t('views.shipping.cols.carrier_name') },
    {
      key: 'shipped_package_count',
      label: t('views.shipping.cols.shipped_package_count'),
      formatter: Formatter.number,
    },
    {
      key: 'average_total_weight_in_kg',
      label: t('views.shipping.cols.average_total_weight_in_kg'),
      formatter: Formatter.round2,
    },
  ];

  const omsShippingStats = {
    columns: commonColumns,
    api: {
      module: 'OMS',
      path: 'address_label_summaries/analytics',
      data_wrapper_opts: {
        data_key: 'data',
      },
      data_wrapper: noWrap,
    },
  };

  const analyticsShippingStats = {
    columns: [
      ...commonColumns,
      {
        key: 'avg_package_value_euro_cents',
        label: t('views.shipping.cols.avg_package_value_euro'),
        formatter: Formatter.euroCentsToEurosWithoutCents,
      },
    ],
    api: {
      module: 'analytics',
      path: 'carriers_distribution/v3',
      data_wrapper_opts: {
        data_key: 'data',
      },
      data_wrapper: noWrap,
    },
  };

  const shippingStats = {
    title: t('views.shipping.overview'),
    id_key: 'carrier_name',
    link: {
      route: '/shipping/details/',
    },
    component: TableCard,
    ...(canUseOmsBackend ? omsShippingStats : analyticsShippingStats),
  };

  const omsShippingProportion = {
    api: {
      module: 'OMS',
      path: 'address_label_summaries/analytics',
      data_wrapper: categoryWrapper,
      data_wrapper_opts: {
        x: 'carrier_name',
        y: 'shipped_package_count',
        data_key: 'data',
      },
    },
  };

  const analyticsShippingProportion = {
    api: {
      module: 'analytics',
      path: 'carriers_distribution/v3',
      data_wrapper: categoryWrapper,
      data_wrapper_opts: {
        x: 'carrier_name',
        y: 'shipped_package_count',
        data_key: 'data',
      },
    },
  };

  const shippingProportion = {
    title: t('views.shipping.proportion'),
    message: t('views.shipping.transporter_proportion_msg'),
    onChange: (selection) => {
      history.push('/shipping/details/' + selection[0].x);
    },
    labels: {
      index: t('charts.units.carrier'),
      value: t('charts.units.packages_sent'),
    },
    formatter: Formatter.number,
    type: 'bar',
    orientation: 'horizontal',
    xtype: 'category',
    height: 300,
    component: ChartCard,
    ...(canUseOmsBackend ? omsShippingProportion : analyticsShippingProportion),
  };

  return [
    <div className="column is-full is-half-widescreen" key="shipping_stats">
      <ContentCard {...props} {...shippingStats} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="shipping_proportion"
    >
      <ContentCard {...props} {...shippingProportion} />
    </div>,
  ];
}
