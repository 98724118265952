import React from 'react';

import { useTranslation } from 'react-i18next';

export default function Select({ id, ...props }) {
  const { t } = useTranslation('translation');

  return (
    <select
      className="selector"
      style={{ width: props.width }}
      onChange={props.handleChange}
      value={props.selection}
      aria-label={props['aria-label']}
    >
      {props.label !== null ? (
        <option value="" disabled key={`${props['aria-label']}-${props.label}`}>
          {t(props.label)}
        </option>
      ) : null}
      {props.options.map((option) => (
        <option value={option.id} key={`${id}-${option.id}`}>
          {props.disableTranslation ? option.label : t(option.label)}
        </option>
      ))}
    </select>
  );
}
