import React from 'react';
import TableCard from '../../ContentCard/TableCard';
import ChartCard from '../../ContentCard/ChartCard';
import ContentCard from '../../ContentCard/ContentCard';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  categoryWithLabelsWrapper,
  categoryWrapper,
  noWrap,
} from '../../../DataTransformFactory';
import Formatter from '../../../FormatFactory';

export default function Performances(props) {
  const { t } = useTranslation('translation');
  const history = useHistory();

  function onOriginChangeBar(selection) {
    history.push('/performances/origin/' + selection[0].x);
  }

  function onOriginChangeDonut(selection) {
    history.push('/performances/origin/' + selection[0]);
  }

  function onSourceChangeBar(selection) {
    history.push('/performances/source/' + selection[0].x);
  }

  function onSourceChangeDonut(selection) {
    history.push('/performances/source/' + selection[0]);
  }

  const best_selling = {
    title: t('views.performances.best_selling'),
    columns: [
      { key: 'product_id', label: t('views.performances.cols.product_id') },
      { key: 'latest_sku', label: t('views.performances.cols.sku') },
      { key: 'latest_title', label: t('views.performances.cols.description') },
      {
        key: 'total_quantity',
        label: t('views.performances.cols.sold_qty'),
        formatter: Formatter.number,
      },
      {
        key: 'latest_stock',
        label: t('views.performances.cols.latest_stock'),
        formatter: Formatter.number,
      },
    ],
    id_key: 'product_id',
    api: {
      module: 'analytics',
      path: 'best_selling_products/table/v1/detailed',
      data_wrapper_opts: {
        data_key: 'data',
      },
      data_wrapper: noWrap,
      queryparams: {
        max_entry_count: 50,
      },
    },
    link: {
      route: '/performances/product/',
    },
    component: TableCard,
  };

  const commands_per_origin = {
    xtype: 'category',
    title: t('views.performances.cmds_per_origin'),
    labels: {
      index: t('charts.units.origin'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.number,
    api: {
      module: 'analytics',
      path: 'orders_distribution/by_origin/v2',
      data_wrapper_opts: { x: 'origin', y: 'order_count', data_key: 'data' },
      data_wrapper: categoryWrapper,
    },
    type: 'bar',
    orientation: 'horizontal',
    onChange: onOriginChangeBar,
    message: t('views.performances.cmds_per_origin_card_msg'),
    height: 400,
    component: ChartCard,
  };

  const commands_per_origin_percent = {
    xtype: 'percent',
    title: t('views.performances.cmds_per_origin'),
    labels: {
      index: t('charts.units.origin'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.number,
    disableLabelFormatter: true,
    api: {
      module: 'analytics',
      path: 'orders_distribution/by_origin/v2',
      data_wrapper_opts: {
        label: 'origin',
        value: 'order_count',
        data_key: 'data',
      },
      data_wrapper: categoryWithLabelsWrapper,
    },
    onChange: onOriginChangeDonut,
    height: 400,
    component: ChartCard,
  };

  const commands_per_source = {
    xtype: 'category',
    title: t('views.performances.cmds_per_source'),
    labels: {
      index: t('charts.units.source'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.number,
    api: {
      module: 'analytics',
      path: 'orders_distribution/by_source/v2',
      data_wrapper_opts: { x: 'source', y: 'order_count', data_key: 'data' },
      data_wrapper: categoryWrapper,
    },
    type: 'bar',
    orientation: 'horizontal',
    onChange: onSourceChangeBar,
    message: t('views.performances.cmds_per_source_card_msg'),
    height: 400,
    component: ChartCard,
  };

  const commands_per_source_percent = {
    xtype: 'percent',
    title: t('views.performances.cmds_per_source'),
    labels: {
      index: t('charts.units.source'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.number,
    api: {
      module: 'analytics',
      path: 'orders_distribution/by_source/v2',
      data_wrapper_opts: {
        label: 'source',
        value: 'order_count',
        data_key: 'data',
      },
      data_wrapper: categoryWithLabelsWrapper,
    },
    onChange: onSourceChangeDonut,
    height: 400,
    component: ChartCard,
  };

  return [
    <div className="column is-full" key="best_selling">
      <ContentCard {...props} {...best_selling} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="commands_per_origin"
    >
      <ContentCard {...props} {...commands_per_origin} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="commands_per_origin_percent"
    >
      <ContentCard {...props} {...commands_per_origin_percent} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="commands_per_source"
    >
      <ContentCard {...props} {...commands_per_source} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="commands_per_source_percent"
    >
      <ContentCard {...props} {...commands_per_source_percent} />
    </div>,
  ];
}
