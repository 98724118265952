import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import menuItems from './menuItems.js';

import { useTranslation } from 'react-i18next';
import { useUser } from './providers';

function MenuItem(props) {
  const { t } = useTranslation('translation');
  const location = useLocation();

  const history = useHistory();

  return (
    <li>
      <div
        onClick={() => history.push(props.item.path)}
        data-testid={props.item.label}
        className={
          location.pathname.indexOf(props.item.path) !== -1
            ? 'menuCard active-menuCard'
            : 'menuCard'
        }
      >
        <div key={props.item.path}>
          <img src={props.item.icon} alt={t(props.item.label)} />
          {t(props.item.label)}
        </div>
      </div>
    </li>
  );
}

export default function Menu(props) {
  const user = useUser();
  return (
    <div
      className={props.visible ? 'menu' : 'menu is-hidden'}
      data-testid="menu"
    >
      <ul className="menu-list">
        {menuItems
          .filter((i) => (i.hidden ? !i.hidden(user) : true))
          .map((item) => (
            <MenuItem item={item} key={item.path} />
          ))}
      </ul>
    </div>
  );
}
