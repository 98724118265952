import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Page404 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div data-testid={'404Page'} className={'container-404'}>
        <h5>{t('access.error')}</h5>
        <h3>404</h3>
        <h5>{t('access.pageNotFound')}</h5>
        <div data-testid={'404PageButton'} onClick={() => history.push('/')}>
          {t('access.backToHomePage')}
        </div>
      </div>
    </>
  );
};
