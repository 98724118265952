import React from 'react';
import { useHistory } from 'react-router-dom';

export default function BreadCrumb(props) {
  const history = useHistory();

  return (
    <div className="breadcrumb" key="breadcrumb">
      <span
        onClick={() => history.push('/' + props.previousRoute)}
        className="breadcrumb-item"
        data-testid={'breadcrumb-item'}
      >
        <img src="/assets/icons/arrow-left.svg" alt={'arrow-left.svg'} />
        {props.previousRouteLabel} /
      </span>
      <span className="lastcrumb">{props.children}</span>
    </div>
  );
}
