import React, { createContext, useContext, useEffect, useState } from 'react';
import API, { headers, URL_MAP } from '../API';

export const UserContext = createContext(null);

export function useUser() {
  return useContext(UserContext);
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});

  useEffect(async () => {
    let alive = true; //
    const data = await API.get(`${URL_MAP.OMS}users/me`, {
      withCredentials: true,
      headers: { ...headers['OMS'] },
    });

    /* istanbul ignore else */
    if (alive) {
      let user = parseUser(data);
      setUser(user);
      if (window.pendo) {
        window.pendo.initialize({
          visitor: {
            id: user.id,
          },
          account: {
            id: user.api_client_id,
          },
          excludeAllText: true,
        });
      }
    }
    /* istanbul ignore next */
    return () => {
      alive = false;
    };
  }, []);

  function parseUser(data) {
    let user = { ...data.data?.user };
    user.canUseOmsBackend =
      user.tms_enabled && user.address_label_summaries_search;
    return user;
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
