export default function sanitizeSeries(series) {
  return series.map((serie) => {
    if (typeof serie === 'object') {
      if (serie.name) {
        return {
          ...serie,
          name: serie.name.replace(
            /[\u00A0-\u9999<>\&]/g,
            (i) => '&#' + i.charCodeAt(0) + ';'
          ),
        };
      } else if (serie.x) {
        return {
          ...serie,
          x: serie.x.replace(
            /[\u00A0-\u9999<>\&]/g,
            (i) => '&#' + i.charCodeAt(0) + ';'
          ),
        };
      } else {
        console.error('unhandled sanitization for ', serie);
        return serie;
      }
    } else if (typeof serie === 'string') {
      return serie.replace(
        /[\u00A0-\u9999<>\&]/g,
        (i) => '&#' + i.charCodeAt(0) + ';'
      );
    } else {
      return serie;
    }
  });
}
