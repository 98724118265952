import React from 'react';
import { useTranslation } from 'react-i18next';

export const InfoBox = ({ textKey, height }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={'info-layout'} style={{ height }}>
        <div className={'info-text'}>
          <span>{t(`components.InfoBox.${textKey}`)}</span>
        </div>

        <div className={'info-text'}>
          <i className={'icon-box-open'} style={{ fontSize: '65px' }}></i>
        </div>
      </div>
    </>
  );
};
