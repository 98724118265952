import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import Select from '../Select/Select';
import {
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION,
} from 'react-dates/lib/constants';
import { useTranslation } from 'react-i18next';

import routesHidden from './routesHidden';
import { useFilter, useUser } from '../../providers';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { OrderSourcesFilter } from '../OrderSourcesFilter';
import { OrderOriginsFilter } from '../OrderOriginsFilter';

export default function FilterBar(props) {
  const { t, i18n } = useTranslation();
  const { saveFilter, filter } = useFilter();
  const { data_retention_since, data_retention_unlimited } = useUser();
  const location = useLocation('translation');
  const [focusedInput1, setFocusedInput1] = useState(null);
  const [focusedInput2, setFocusedInput2] = useState(null);
  const [preselectOption, setPreselectOption] = useState('last_week');

  const preselectedDates = [
    {
      id: 'today',
      label: 'datepicker.selection.today',
      starting_date: moment(),
      end_date: moment(),
    },
    {
      id: 'last_week',
      label: 'datepicker.selection.last_week',
      starting_date: moment().subtract(1, 'week'),
      end_date: moment(),
    },
    {
      id: 'last_month',
      label: 'datepicker.selection.last_month',
      starting_date: moment().subtract(1, 'month'),
      end_date: moment(),
    },
    {
      id: 'last_year',
      label: 'datepicker.selection.last_year',
      starting_date: moment().subtract(1, 'year'),
      end_date: moment(),
    },
  ];

  const min_date_for_user = useMemo(() => {
    if (data_retention_since) {
      return moment(data_retention_since);
    }
    if (data_retention_unlimited) {
      return moment('01-01-2015');
    }
    return moment().subtract(2, 'year'); // old default if no retention defined
  }, [data_retention_since, data_retention_unlimited]);

  const datepicker_settings = useMemo(() => {
    return {
      firstDayOfWeek: 1,
      hideKeyboardShortcutsPanel: true,
      maxDate: moment(),
      minDate: min_date_for_user,
      isOutsideRange: (date) =>
        date.isBefore(datepicker_settings.minDate, 'day') ||
        date.isAfter(datepicker_settings.maxDate, 'day'),
    };
  }, [min_date_for_user]);

  useEffect(() => {
    let { preset } = filter;
    if (preset) {
      setPreselectOption(preset);
    } else {
      setPreselectOption('');
    }
  }, [filter]);

  function handlePreselect(event) {
    const selection = event.target.value;
    const preselection = findPreset(selection);
    updateFilter(
      preselection.starting_date,
      preselection.end_date,
      preselection.id
    );

    setPreselectOption(preselection.id);
  }

  function resetPreselect() {
    setPreselectOption('');
  }

  const findPreset = (id) => {
    return preselectedDates.find((option) => option.id === id);
  };

  const updateFilter = (starting_date, end_date, preset = undefined) => {
    saveFilter({
      ...filter,
      starting_date: starting_date,
      end_date: end_date,
      preset,
    });
  };

  return (
    /* Date picker */
    <div
      className={
        routesHidden.indexOf(location.pathname) !== -1
          ? 'is-hidden'
          : 'filter-bar'
      }
      data-testid="filterbar"
    >
      <Select
        options={preselectedDates}
        width="160px"
        label="datepicker.quick_selection"
        aria-label="preselecteddates-selector"
        handleChange={handlePreselect}
        selection={preselectOption}
      />
      <span className="is-hidden-tablet date-picker-small">
        <DateRangePicker
          {...datepicker_settings}
          startDate={moment(filter.starting_date)}
          endDate={moment(filter.end_date)}
          startDatePlaceholderText={t('datepicker.startlabel')}
          startDateId="start_date_id_1"
          withPortal={true}
          withFullScreenPortal={true}
          endDatePlaceholderText={t('datepicker.endlabel')}
          orientation={VERTICAL_ORIENTATION}
          endDateId="end_date_id_1"
          onDatesChange={({ startDate, endDate }) => {
            updateFilter(startDate, endDate);
            resetPreselect();
          }}
          focusedInput={focusedInput1}
          displayFormat={() => 'DD MMM YYYY'}
          onFocusChange={(focusedInput1) => setFocusedInput1(focusedInput1)}
          minimumNights={0}
          renderMonthElement={({ month }) => {
            return moment(month).format('MMMM YYYY');
          }}
        />
      </span>
      <span className="is-hidden-mobile date-picker-wide">
        <DateRangePicker
          {...datepicker_settings}
          startDate={moment(filter.starting_date)}
          endDate={moment(filter.end_date)}
          startDatePlaceholderText={t('datepicker.startlabel')}
          startDateId="start_date_id_2"
          endDatePlaceholderText={t('datepicker.endlabel')}
          endDateId="end_date_id_2"
          orientation={HORIZONTAL_ORIENTATION}
          onDatesChange={({ startDate, endDate }) => {
            updateFilter(startDate, endDate);
            resetPreselect();
          }}
          focusedInput={focusedInput2}
          displayFormat={() => 'DD MMM YYYY'}
          minimumNights={0}
          onFocusChange={(focusedInput2) => setFocusedInput2(focusedInput2)}
          renderMonthElement={({ month }) => {
            return moment(month).format('MMMM YYYY');
          }}
        />
      </span>
      <span className={'order-sources-filter'}>
        <OrderSourcesFilter />
      </span>
      {props.showOriginFilter && (
        <span className={'order-origins-filter'}>
          <OrderOriginsFilter />
        </span>
      )}
    </div>
  );
}
