import React from 'react';
import DatetimeChart from '../SBICharts/DatetimeChart';
import CategoryChart from '../SBICharts/CategoryChart';
import MultiSeriesDatetimeChart from '../SBICharts/MultiSeriesDatetimeChart';
import { useTranslation } from 'react-i18next';

export default function EvoCard(props) {
  const { data } = props;
  const { t } = useTranslation('translation');

  function EvoIcon() {
    if (data.evo.interpreted_trend > 0) {
      return (
        <div>
          <img
            className="positive-growth"
            src="/assets/icons/mini-chevron-up.svg"
            aria-label="positive-growth"
          />
          {t('trend.up')}
        </div>
      );
    }
    if (data.evo.interpreted_trend < 0) {
      return (
        <div>
          <img
            className="negative-growth"
            src="/assets/icons/mini-chevron-down.svg"
            aria-label="negative-growth"
          />
          {t('trend.down')}
        </div>
      );
    }
    return <span className="null-growth">-- {t('trend.neutral')}</span>;
  }

  function EvoBlock() {
    if (
      data.evo.interpreted_trend !== undefined &&
      data.evo.interpreted_trend !== null
    ) {
      return (
        <div className="evo-block">
          <div className="evo-block-label">{t('trend.globaltrend')}</div>
          <div className="evo-block-value">
            <EvoIcon />
          </div>
        </div>
      );
    }
    return null;
  }

  function ChartWrapper(props) {
    return [
      <EvoBlock key="evo-block" />,
      <div className="chart-block" key="chart-block">
        {props.children}
      </div>,
    ];
  }

  function chartComponentSwitch() {
    switch (props.xtype) {
      case 'datetime':
        return (
          <ChartWrapper>
            <DatetimeChart {...props} data={data.data} />
          </ChartWrapper>
        );
      case 'multiseriesdatetime':
        return (
          <ChartWrapper>
            <MultiSeriesDatetimeChart {...props} data={data.data} />
          </ChartWrapper>
        );
      case 'category':
        return (
          <ChartWrapper>
            <CategoryChart {...props} data={data.data} />
          </ChartWrapper>
        );
      default:
        return props.xtype + ' is not recognized in EvoCard';
    }
  }

  return <div className="EvoCard">{chartComponentSwitch()}</div>;
}
