import React from 'react';
import ContentCard from '../../ContentCard/ContentCard';
import TableCard from '../../ContentCard/TableCard';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { noWrap } from '../../../DataTransformFactory';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import Formatter from '../../../FormatFactory';

export default function OffersSource(props) {
  const { t } = useTranslation('translation');
  const { source } = useParams();

  const best_selling = {
    title: t('views.offers.overview'),
    subtitle: source,
    columns: [
      { key: 'product_ref', label: t('views.offers.cols.product_ref') },
      {
        key: source,
        label: t('views.performances.cols.sold_qty_for_source'),
        formatter: Formatter.number,
      },
    ],
    id_key: 'product_id',
    api: {
      module: 'analytics',
      path: 'offers_orderings/full_table_by_source_performance/v2/detailed',
      data_wrapper: noWrap,
      data_wrapper_opts: {
        data_key: 'data',
      },
      queryparams: {
        source: source,
      },
    },
    component: TableCard,
  };

  return [
    <BreadCrumb
      previousRoute="offers"
      previousRouteLabel={t('menu.offers')}
      key="breadcrumb"
    >
      {t('views.offers.source')} : <span className="selectable">{source}</span>
    </BreadCrumb>,
    <div className="column is-full" key="best_selling">
      <ContentCard {...props} {...best_selling} />
    </div>,
  ];
}
