const factory = {
  euroCentsToEurosWithoutCents: (value) =>
    (value !== null && Math.round(value / 100).toLocaleString()) || '',
  number: (value) =>
    (value !== null && value !== undefined && value.toLocaleString()) || '',
  round: (value) => Math.round(value),
  round2: (value) => Math.round(value * 100) / 100,
  noFormat: (value) => value,
};

export default factory;
