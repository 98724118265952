import React, { useCallback, useEffect, useState } from 'react';

import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import chartColors from './chartColors.js';

import fr from 'apexcharts/dist/locales/fr.json';
import en from 'apexcharts/dist/locales/en.json';
import es from 'apexcharts/dist/locales/es.json';

import sanitizeSeries from '../../sanitizeSeries';

import Helpers from './SBIChartHelpers';
import { useData } from '../../providers';

export function labelsFormatter(val) {
  return Math.round(val);
}

export default function MultiSeriesDateTimeChart(props) {
  const { i18n } = useTranslation('translation');
  const { orderSources } = useData();

  const formatLabel = useCallback(
    (series) => {
      /*istanbul ignore if*/
      if (props.disableLabelFormatter) {
        return series;
      }
      return series.map((item) => {
        const source = orderSources.find((os) => os.name === item.name);
        return {
          ...item,
          name: source?.display_name || item.name,
        };
      });
    },
    [orderSources, props.disableLabelFormatter]
  );

  const getColors = useCallback(() => {
    const result = props.data.series.map(function (serie, index) {
      if (props.custom_colors) {
        return props.custom_colors[serie.key] || chartColors[index];
      }
      return chartColors[index];
    });
    return result.filter((elem) => !!elem);
  }, [props.custom_colors, props.data.series]);

  const [state, setState] = useState({
    options: {
      chart: {
        stacked: props.stacked || false,
        zoom: {
          enabled: false,
        },
        locales: [fr, en, es],
        defaultLocale: i18n.language.substring(0, 2),
        toolbar: {
          tools: {
            download: "<img src='/assets/icons/download.svg' width='16px' />",
          },
          export: {
            csv: {
              columnDelimiter: Helpers.csvSeparator(i18n.language),
            },
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      stroke: {
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        categories: [],
      },
      yaxis: {
        labels: {
          formatter: props.formatter || labelsFormatter,
        },
      },
      legend: {
        position: 'top',
      },
      fill: {
        opacity: 1,
      },
      colors: getColors(),
      tooltip: {
        x: {
          format: 'dddd dd MMMM',
        },
      },
    },
    series: [],
  });

  // Refresh when props are changing (i.e change in filters)
  useEffect(() => {
    setState({
      options: {
        chart: {
          stacked: props.stacked || false,
          zoom: {
            enabled: false,
          },
          locales: [fr, en],
          defaultLocale: i18n.language.substring(0, 2),
          toolbar: {
            tools: {
              download: "<img src='/assets/icons/download.svg' width='16px' />",
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          type: 'datetime',
          categories: props.data.dates,
        },
        yaxis: {
          labels: {
            formatter: props.formatter || labelsFormatter,
          },
        },
        legend: {
          position: 'top',
        },
        fill: {
          opacity: 1,
        },
        colors: getColors(),
        tooltip: {
          x: {
            format: 'dddd dd MMMM',
          },
        },
      },
      series: sanitizeSeries(props.data.series),
    });
  }, [props, i18n.language, getColors]);

  return (
    <Chart
      options={state.options}
      series={formatLabel(state.series)}
      type={props.type}
      width="100%"
      height={props.height}
    />
  );
}
