import React from 'react';
import { UserProvider, useUser } from './UserProvider';
import { FilterProvider, useFilter } from './FilterProvider';
import { DataProvider, useData } from './DataProvider';

export const Providers = ({ children }) => {
  return (
    <>
      <UserProvider>
        <DataProvider>
          <FilterProvider>{children}</FilterProvider>
        </DataProvider>
      </UserProvider>
    </>
  );
};

export { useFilter, useUser, useData };
