import React from 'react';
import StatsCard from '../../ContentCard/StatsCard';
import ChartCard from '../../ContentCard/ChartCard';
import ContentCard from '../../ContentCard/ContentCard';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import {
  categoryWithLabelsWrapper,
  categoryWrapper,
  datetimeWrapper,
  multiDateTimeWrapper,
  simpleArrayWrapper,
} from '../../../DataTransformFactory';
import Formatter from '../../../FormatFactory';
import Reducers from '../../../ReducerFactory';
import { useFilter } from '../../../providers';

export default function Offer(props) {
  const { t } = useTranslation('translation');
  const { offer } = useParams();
  const { filter } = useFilter();

  const offer_stats_items = [
    {
      title: 'views.offer.total',
      reducer: Reducers.total,
    },
  ];

  if (!filter.singleDay) {
    offer_stats_items.push({
      title: 'views.offer.average',
      reducer: Reducers.avg,
      formatter: Formatter.round,
    });
  }

  const offer_stats = {
    title: t('views.offer.summary'),
    items: offer_stats_items,
    api: {
      module: 'analytics',
      path: 'daily_offer_orderings/by_source/v1',
      data_wrapper_opts: {
        key: 'total_quantity',
        data_key: ['daily_values', 'data'],
      },
      data_wrapper: simpleArrayWrapper,
      queryparams: {
        offer_ref: offer,
      },
    },
    height: 120,
    component: StatsCard,
  };

  const daily_offers = {
    xtype: 'datetime',
    title: t('views.offer.offer_selling_figures'),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    api: {
      module: 'analytics',
      path: 'daily_offer_orderings/by_source/v1',
      data_wrapper_opts: {
        x: 'date',
        y: 'total_quantity',
        data_key: ['daily_values', 'data'],
      },
      data_wrapper: datetimeWrapper,
      queryparams: {
        offer_ref: offer,
      },
    },
    type: 'line',
    orientation: 'vertical',
    height: 400,
    component: ChartCard,
  };

  const daily_offers_by_source = {
    xtype: 'multiseriesdatetime',
    title: t('views.offer.daily_offers_by_source'),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.QTY'),
    },
    api: {
      module: 'analytics',
      path: 'daily_offer_orderings/by_source/v1',
      queryparams: {
        offer_ref: offer,
      },
      data_wrapper_opts: {
        x: 'date',
        data_key: ['daily_values', 'data'],
        excluded_cols: ['index', 'total_quantity'],
      },
      data_wrapper: multiDateTimeWrapper,
    },
    stacked: false,
    type: 'line',
    orientation: 'vertical',
    height: 400,
    component: ChartCard,
  };

  const offers_per_origin = {
    xtype: 'category',
    title: t('views.offer.origin_offer_sellings_figures'),
    labels: {
      index: t('charts.units.origin'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    api: {
      module: 'analytics',
      path: 'offer_orderings_distribution/by_origin/v1',
      data_wrapper_opts: {
        x: 'origin',
        y: 'total_quantity',
        data_key: 'data',
      },
      data_wrapper: categoryWrapper,
      queryparams: {
        offer_ref: offer,
      },
    },
    type: 'bar',
    orientation: 'horizontal',
    height: 400,
    component: ChartCard,
  };

  const offers_per_origin_percent = {
    xtype: 'percent',
    title: t('views.offer.origin_offer_sellings_figures'),
    labels: {
      index: t('charts.units.origin'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    disableLabelFormatter: true,
    api: {
      module: 'analytics',
      path: 'offer_orderings_distribution/by_origin/v1',
      data_wrapper_opts: {
        label: 'origin',
        value: 'total_quantity',
        data_key: 'data',
      },
      data_wrapper: categoryWithLabelsWrapper,
      queryparams: {
        offer_ref: offer,
      },
    },
    height: 400,
    component: ChartCard,
  };

  const offers_per_source = {
    xtype: 'category',
    title: t('views.offer.source_offer_sellings_figures'),
    labels: {
      index: t('charts.units.source'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    api: {
      module: 'analytics',
      path: 'offer_orderings_distribution/by_source/v1',
      data_wrapper_opts: {
        x: 'source',
        y: 'total_quantity',
        data_key: 'data',
      },
      data_wrapper: categoryWrapper,
      queryparams: {
        offer_ref: offer,
      },
    },
    type: 'bar',
    orientation: 'horizontal',
    height: 400,
    component: ChartCard,
  };

  const offers_per_source_percent = {
    xtype: 'percent',
    title: t('views.offer.source_offer_sellings_figures'),
    labels: {
      index: t('charts.units.source'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.noFormat,
    api: {
      module: 'analytics',
      path: 'offer_orderings_distribution/by_source/v1',
      data_wrapper_opts: {
        label: 'source',
        value: 'total_quantity',
        data_key: 'data',
      },
      data_wrapper: categoryWithLabelsWrapper,
      queryparams: {
        offer_ref: offer,
      },
    },
    height: 400,
    component: ChartCard,
  };

  return [
    <BreadCrumb
      previousRoute="offers"
      previousRouteLabel={t('menu.offers')}
      key="breadcrumb"
    >
      {t('views.offer.offer')} :{' '}
      <span className="selectable">{decodeURIComponent(offer)}</span>
    </BreadCrumb>,
    <div className="column is-full" key="offer_stats">
      <ContentCard {...props} {...offer_stats} />
    </div>,
    <>
      {filter.singleDay
        ? null
        : [
            <div className="column is-full" key="daily_offer_sellings">
              <ContentCard {...props} {...daily_offers} />
            </div>,
            <div className="column is-full" key="daily_offers_by_source">
              <ContentCard {...props} {...daily_offers_by_source} />
            </div>,
          ]}
    </>,
    /*
     <div
     className="column is-full is-half-widescreen"
     key="offer_sellings_per_origin"
     >
     <ContentCard {...props} {...offers_per_origin} />
     </div>,
     <div
     className="column is-full is-half-widescreen"
     key="offer_sellings_per_origin_percent"
     >
     <ContentCard {...props} {...offers_per_origin_percent} />
     </div>,*/
    <div
      className="column is-full is-half-widescreen"
      key="offer_sellings_per_source"
    >
      <ContentCard {...props} {...offers_per_source} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="offer_sellings_per_source_percent"
    >
      <ContentCard {...props} {...offers_per_source_percent} />
    </div>,
  ];
}
