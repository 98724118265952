import React from 'react';
import { useTranslation } from 'react-i18next';

/*
Required props as : 
{
  "title":'title',
  "api":{
    "module": "OMS",
    "path": 'orders/live_overview'
  },
  'height': 400,
  'translation_domain': 'views.commands.summary',
  'keys':['to_be_prepared','waiting_for_stock',...] # Optionnal
  'custom_colors': {
    "to_be_prepared":'#70daf2',
    "waiting_for_stock":'#fb9e32',
    "dispatched":'#000000',
    "in_trouble":'#ff4b4b',
    "in_preparation":'#000000',
    "created_today":'#000000',
    "shipped_today":'#5bda05'
  }
}
*/
export default function SummaryCard(props) {
  const { data } = props;
  const { t } = useTranslation('translation');

  const focusedData = props.keys.reduce((obj, key) => {
    obj[key] = data[key];
    return obj;
  }, {});

  function WithLink(props) {
    if (props.links && props.links[props.item]) {
      return (
        <a href={props.links[props.item]} target="_blank" rel="noreferrer">
          {props.children}
        </a>
      );
    }
    return <div>{props.children}</div>;
  }

  return (
    <div className="summary-content">
      {Object.keys(focusedData).map((key, index) => (
        <WithLink key={key} item={key} links={props.links}>
          <div className="summary-item">
            <div
              className="summary-item-value"
              style={{ color: props.custom_colors[key] }}
              data-testid={key}
            >
              {focusedData[key]}
            </div>
            <div className="summary-item-title">
              {t(props.translation_domain + '.' + key)}
            </div>
          </div>
        </WithLink>
      ))}
    </div>
  );
}
