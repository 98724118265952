import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NoAccess(props) {
  const { t } = useTranslation('translation');

  return (
    <div className="full-content-centered">
      <img
        src="/assets/icons/eye-crossed.svg"
        alt="logo"
        width="75"
        height="75"
      />
      <div className="content-message">{t('access.noaccess.msg')}</div>
    </div>
  );
}
