import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useData } from './DataProvider';

export const FilterContext = createContext(null);

export function useFilter() {
  return useContext(FilterContext);
}

const KEY = 'ANALYTICS_FILTER';

export const FilterProvider = ({ children }) => {
  const { orderSources, sourcesLoaded } = useData();
  const [filter, setFilter] = useState({
    starting_date: moment().subtract(7, 'day'),
    end_date: moment(),
    preset: 'last_week',
    singleDay: false,
    time_zone: null,
  });

  useEffect(() => {
    let _filter;
    const itemStr = localStorage.getItem(KEY);

    if (!itemStr) {
      _filter = filter;
    } else {
      const item = JSON.parse(itemStr);

      const now = new Date();

      if (now.getTime() > item.expiry) {
        localStorage.removeItem(KEY);
        _filter = { preset: 'last_week' };
      } else {
        delete item.value.start;
        delete item.value.end;
        _filter = item.value;
      }
    }

    _filter.time_zone = moment.tz.guess();

    saveFilter(_filter);
  }, []);

  useEffect(
    function () {
      if (sourcesLoaded) {
        const sourceExists = (source) =>
          orderSources.map((os) => os.name).includes(source);
        if (filter?.sources?.length && !filter.sources.every(sourceExists)) {
          saveFilter({
            ...filter,
            sources: filter.sources.filter(sourceExists),
          });
        }
      }
    },
    [orderSources, sourcesLoaded, filter?.sources]
  );

  const saveFilter = (_filter, ttl = 500000) => {
    const now = new Date();

    const item = {
      value: { ...filter, ...cleanDates(_filter) },
      expiry: now.getTime() + ttl,
    };

    item.value.singleDay = moment(item.value.starting_date)
      .startOf('day')
      .isSame(moment(item.value.end_date).startOf('day'));

    setFilter(item.value);
    localStorage.setItem(KEY, JSON.stringify(item));
  };

  const getFilter = () => {
    const _filter = removeEmpty(format(filter));
    delete _filter.singleDay;
    delete _filter.preset;
    return _filter;
  };

  const removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] == null) {
        delete obj[key];
      }
    });
    return obj;
  };

  const cleanDates = ({ starting_date, end_date, ...obj }) => {
    const cleanedObject = { ...obj };
    if (starting_date) {
      cleanedObject.starting_date = starting_date;
    }
    if (end_date) {
      cleanedObject.end_date = end_date;
    }
    return cleanedObject;
  };

  const format = (filter) => {
    return {
      ...filter,
      starting_date: moment(filter.starting_date).format('YYYY-MM-DD'),
      end_date: moment(filter.end_date).format('YYYY-MM-DD'),
    };
  };

  return (
    <>
      <FilterContext.Provider value={{ filter: filter, saveFilter, getFilter }}>
        {children}
      </FilterContext.Provider>
    </>
  );
};
