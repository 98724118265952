import React, { useEffect, useState } from 'react';
import EvoCard from '../../ContentCard/EvoCard';
import StatsCard from '../../ContentCard/StatsCard';
import ContentCard from '../../ContentCard/ContentCard';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { evoWrapper, simpleArrayWrapper } from '../../../DataTransformFactory';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import Formatter from '../../../FormatFactory';
import API, { headers, OMSLink, URL_MAP } from '../../../API';
import Reducers from '../../../ReducerFactory';

export default function PerformancesProduct(props) {
  const { t } = useTranslation('translation');
  const { product } = useParams();
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setProductDetails(null);
      const result = await API.get(`${URL_MAP.OMS}products/${product}`, {
        withCredentials: true,
        headers: { ...headers['OMS'] },
      });

      if (result) {
        setProductDetails(result.data.product);
      }
    }

    fetchData();
  }, [product]);

  function periodIsOneDay() {
    if (props.start !== null && props.end !== null) {
      return props.end.diff(props.start, 'days') < 2;
    } else {
      return false;
    }
  }

  function getOrdersListUrl() {
    return OMSLink(
      `#/orders?search=%7B"joins"%3A%7B"products"%3A%7B"id__eq"%3A%5B"${product}"%5D%7D%7D%7D`
    );
  }

  const selling_evo = {
    xtype: 'datetime',
    title: t('trend.evolution'),
    subtitle: productName(),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.QTY'),
    },
    formatter: Formatter.number,
    api: {
      module: 'analytics',
      path: 'product_sales/daily/v2',
      data_wrapper_opts: {
        x: 'date',
        y: 'total_quantity',
        evo_key: ['ols'],
        data_key: ['time_series', 'data'],
      },
      data_wrapper: evoWrapper,
      queryparams: {
        product_id: product,
      },
    },
    type: 'line',
    orientation: 'vertical',
    height: 250,
    component: EvoCard,
  };

  const product_stats = {
    title: t('views.performancesProduct.totals'),
    subtitle: productName(),
    items: [
      {
        title: 'views.performancesProduct.total',
        reducer: Reducers.total,
        formatter: Formatter.round,
      },
      {
        title: 'views.performancesProduct.average',
        reducer: Reducers.avg,
        formatter: Formatter.round,
      },
    ],
    api: {
      module: 'analytics',
      path: 'product_sales/daily/v2',
      data_wrapper_opts: {
        key: 'total_quantity',
        data_key: ['time_series', 'data'],
      },
      data_wrapper: simpleArrayWrapper,
      queryparams: {
        product_id: product,
      },
    },
    height: 120,
    component: StatsCard,
  };

  function productName() {
    if (productDetails) {
      return productDetails.user_ref + ' - ' + productDetails.title;
    }
    return product;
  }

  function displayedWidgets() {
    const widgets = [
      <BreadCrumb
        previousRoute="performances"
        previousRouteLabel={t('menu.performances')}
        key="breadcrumb"
      >
        {t('views.performances.product')} :{' '}
        <span className="selectable">{productName()}</span>
      </BreadCrumb>,
      <a target="_blank" href={getOrdersListUrl()} className="secondaryBtn">
        {t('views.performances.verifyOrders')}
      </a>,
      <div className="column is-full" key="product_stats">
        <ContentCard {...props} {...product_stats} />
      </div>,
    ];

    if (!periodIsOneDay()) {
      widgets.push(
        <div className="column is-full" key="selling_evo">
          <ContentCard {...props} {...selling_evo} />
        </div>
      );
    }

    return widgets;
  }

  return displayedWidgets();
}
