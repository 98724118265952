export const sumBy = (arr, key) => {
  return arr.reduce(
    (accumulator, current) => accumulator + Number(current[key]),
    0
  );
};

export const alphaNumSort = (a, b) => {
  if (Number.isInteger(a) && Number.isInteger(b)) {
    return a - b;
  } else {
    return (a?.toString() || '')
      .toLowerCase()
      .localeCompare((b?.toString() || '').toLowerCase());
  }
};
