import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import ShippingBI from './ShippingBI';
import './i18n';
import { Providers } from './providers';

let inIframe = null;

if (window.location !== window.parent.location) {
  inIframe = true;
} else {
  inIframe = false;
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="Loading Translations...">
      <Providers>
        <ShippingBI iframeMode={inIframe} />
      </Providers>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
