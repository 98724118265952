import React from 'react';
import { useTranslation } from 'react-i18next';
import Formatter from '../../FormatFactory';
import { focusData } from '../../DataTransformFactory';

export default function StatsCard(props) {
  const { data, items } = props;

  const { t } = useTranslation();

  const formattedData = items.map((item) => {
    const formatter = item.formatter || Formatter.noFormat;
    let focusedData = focusData(data, item.key);

    return {
      title: t(item.title),
      value: formatter(focusedData.reduce(item.reducer, 0)),
      color: item.color,
    };
  });

  return (
    <div className="summary-content">
      {formattedData.map((datum) => (
        <div className="stats-item" key={datum.title}>
          <div className="stats-item-value" style={{ color: datum.color }}>
            {datum.value}
          </div>
          <div className="stats-item-title">{datum.title}</div>
        </div>
      ))}
    </div>
  );
}
