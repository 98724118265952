import momentTz from 'moment-timezone';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { setupCache } from 'axios-cache-interceptor';

const API_URL = process.env.REACT_APP_DATA_API_URL;
const OMS_URL = process.env.REACT_APP_OMS_SERVER;
const CONNECT_URL = process.env.REACT_APP_CONNECT_URL;

export const URL_MAP = {
  OMS: OMS_URL,
  analytics: API_URL,
  connect: CONNECT_URL,
};

const API = setupCache(axios, {
  ttl: 30 * 1000,
});

let refreshingCookiePromise = null;
let redirecting = false;

/* istanbul ignore next */
axios.interceptors.request.use(async (request) => {
  if (!request.url.includes('/users/refresh_cookie')) {
    if (!document.cookie.match(/^(.*;)?\s*shippingbo_auth\s*=\s*[^;]+(.*)?$/)) {
      if (!refreshingCookiePromise) {
        refreshingCookiePromise = axios
          .get(`${URL_MAP['OMS']}users/refresh_cookie`, {
            withCredentials: true,
            skipAuthRefresh: true,
          })
          .then((response) => {
            refreshingCookiePromise = null;
            return request;
          })
          .catch((error) => {
            // Session is dead, go to OMS login
            console.error(
              'Could not refresh auth_cookie in pre flight interceptor. You need to sign-in again.'
            );
            redirecting = true;
            goToLogin();
          });
        return refreshingCookiePromise;
      } else {
        // cookie already refreshing, waiting for response to continue
        await refreshingCookiePromise;
        if (!redirecting) return request;
        return null;
      }
    }
  }
  return request;
});

export function goToLogin() {
  const current_location = window.location.href;
  window.location.href =
    OMS_URL + 'users/sign_in?checklogin=true&goto=' + current_location;
}

export function OMSLink(path) {
  return URL_MAP.OMS + path;
}

// Should not happen that much now, but still is usefull if sessions are invalidated server side
export const refreshAuthLogic = async (failedRequest) => {
  return new Promise((resolve, reject) => {
    if (failedRequest.config.url.indexOf(OMS_URL) !== -1) {
      // Session is dead, go to OMS login
      goToLogin();
    } else {
      // Analytics cookie expired
      // Try to renew the cookie
      return axios
        .get(`${URL_MAP['OMS']}users/refresh_cookie`, {
          withCredentials: true,
          skipAuthRefresh: true,
        })
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          // Session is dead, go to OMS login
          console.error(
            'Could not refresh auth_cookie. You need to sign-in again.'
          );
          goToLogin();
          reject();
        });
    }
  });
};

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  statusCodes: [403, 401],
});

const tz = momentTz.tz.guess();

export const headers = {
  OMS: { 'X-ActiveModelSerializers': 1 },
  analytics: {},
};

export default API;
