import React, { useState } from 'react';
import ContentPane from './ContentPane';
import Menu from './Menu';
import Navbar from './Navbar';
import FilterBar from './components/FilterBar/FilterBar';
import { BrowserRouter as Router } from 'react-router-dom';
import moment from 'moment';

/* istanbul ignore if */
if (process.env.REACT_APP_PENDO_KEY) {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push'](
                [m].concat([].slice.call(arguments, 0))
              );
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src =
        'https://content.pendo.shippingbo.com/agent/static/' +
        apiKey +
        '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })(process.env.REACT_APP_PENDO_KEY);
}

/* Communication Layer of the application */
export default function ShippingBI(props) {
  const [state, setState] = useState({
    isMenuOpen: !props.iframeMode,
    startDate: moment().subtract(7, 'day'),
    endDate: moment(),
  });

  function toggleMenu() {
    setState({
      isMenuOpen: !state.isMenuOpen,
      startDate: state.startDate,
      endDate: state.endDate,
    });
  }

  return (
    <div className={'App' + (props.iframeMode ? ' asIframe' : '')}>
      <Router>
        {props.iframeMode ? null : <Navbar togglemenu={toggleMenu} />}
        <div className="full_content">
          {props.iframeMode ? null : (
            <span className="is-hidden-mobile elevation-20">
              <Menu visible={state.isMenuOpen} />
            </span>
          )}
          <div
            className={
              state.isMenuOpen && !props.iframeMode
                ? 'main-pane withmenu'
                : 'main-pane'
            }
          >
            <FilterBar start={state.startDate} end={state.endDate} />
            <ContentPane start={state.startDate} end={state.endDate} />
          </div>
        </div>
      </Router>
    </div>
  );
}
