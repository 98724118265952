/* Ordering will be respected in UI */
const menuItems = [
  {
    label: 'menu.dashboard',
    icon: '/assets/icons/dashboard.svg',
    path: '/dashboard',
  },
  {
    label: 'menu.orders',
    icon: '/assets/icons/cart.svg',
    path: '/orders',
  },
  {
    label: 'menu.revenue',
    icon: '/assets/icons/euro.svg',
    path: '/revenue',
    hidden: (user) => user.service_credentials_enabled !== true,
  },
  {
    label: 'menu.performances',
    icon: '/assets/icons/tag-dollar.svg',
    path: '/performances',
  },
  {
    label: 'menu.offers',
    icon: '/assets/icons/tag-dollar.svg',
    path: '/offers',
  },
  {
    label: 'menu.shipping',
    icon: '/assets/icons/transport.svg',
    path: '/shipping',
  },
];

export default menuItems;
