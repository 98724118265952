import React from 'react';
import ContentCard from '../../ContentCard/ContentCard';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import { useUser } from '../../../providers';
import TableCard from '../../ContentCard/TableCard';
import Loader from '../../Loader/Loader';
import Formatter from '../../../FormatFactory';
import { noWrap } from '../../../DataTransformFactory';

export default function ShippingCarrier(props) {
  const { t } = useTranslation('translation');
  const { carrier } = useParams();
  const { canUseOmsBackend } = useUser();

  if (canUseOmsBackend === undefined) {
    // Try again later when user is loaded
    return <Loader height={500} />;
  }

  const commonColumns = [
    {
      key: 'shipped_package_count',
      label: t('views.shipping.cols.shipped_package_count'),
      formatter: Formatter.number,
    },
    {
      key: 'average_total_weight_in_kg',
      label: t('views.shipping.cols.average_total_weight_in_kg'),
      formatter: Formatter.round2,
    },
    {
      key: 'FR',
      label: t('views.shipping.carrier.cols.fromFR'),
      formatter: Formatter.number,
    },
    {
      key: 'EU',
      label: t('views.shipping.carrier.cols.fromEU'),
      formatter: Formatter.number,
    },
    {
      key: 'outside_eu',
      label: t('views.shipping.carrier.cols.from_outside'),
      formatter: Formatter.number,
    },
  ];

  const commonAnalyticsColumns = [
    ...commonColumns.slice(0, 2),
    {
      key: 'avg_package_value_euro_cents',
      label: t('views.shipping.cols.avg_package_value_euro'),
      formatter: Formatter.euroCentsToEurosWithoutCents,
    },
    ...commonColumns.slice(2),
  ];

  const omsPerSource = {
    columns: [
      { key: 'source', label: t('views.shipping.carrier.cols.source') },
      ...commonColumns,
    ],
    api: {
      module: 'OMS',
      path: 'address_label_summaries/analytics',
      data_wrapper_opts: {
        data_key: 'data',
      },
      data_wrapper: noWrap,
      queryparams: {
        carrier_name: carrier,
        group_by: 'source',
      },
    },
  };

  const analyticsPerSource = {
    columns: [
      { key: 'source', label: t('views.shipping.carrier.cols.source') },
      ...commonAnalyticsColumns,
    ],
    api: {
      module: 'analytics',
      path: 'carrier_use/by_source/v3',
      data_wrapper_opts: {
        data_key: 'data',
      },
      data_wrapper: noWrap,
      queryparams: {
        carrier_name: carrier,
      },
    },
    pivotData: {
      api: {
        module: 'analytics',
        path: 'carrier_destination/by_source/v2',
        data_wrapper: noWrap,
        data_wrapper_opts: {
          data_key: 'data',
        },
        queryparams: {
          carrier_name: carrier,
        },
      },
      pivotable_options: ['FR', 'EU', 'outside_eu'],
      id_key: 'source',
      pivotable_label: 'area',
      pivotable_value: 'shipped_package_count',
    },
  };

  const perSource = {
    title: t('views.shipping.carrier.by_source'),
    subtitle: carrier,
    id_key: 'source',
    component: TableCard,
    ...(canUseOmsBackend ? omsPerSource : analyticsPerSource),
  };

  const omsPerOrigin = {
    columns: [
      { key: 'origin', label: t('views.shipping.carrier.cols.origin') },
      ...commonColumns,
    ],
    api: {
      module: 'OMS',
      path: 'address_label_summaries/analytics',
      data_wrapper: noWrap,
      data_wrapper_opts: {
        data_key: 'data',
      },
      queryparams: {
        carrier_name: carrier,
        group_by: 'origin',
      },
    },
  };

  const analyticsPerOrigin = {
    columns: [
      { key: 'origin', label: t('views.shipping.carrier.cols.origin') },
      ...commonAnalyticsColumns,
    ],
    api: {
      module: 'analytics',
      path: 'carrier_use/by_origin/v3',
      data_wrapper: noWrap,
      data_wrapper_opts: {
        data_key: 'data',
      },
      queryparams: {
        carrier_name: carrier,
      },
    },
    pivotData: {
      api: {
        module: 'analytics',
        path: 'carrier_destination/by_origin/v2',
        data_wrapper: noWrap,
        data_wrapper_opts: {
          data_key: 'data',
        },
        queryparams: {
          carrier_name: carrier,
        },
      },
      pivotable_options: ['FR', 'EU', 'outside_eu'],
      id_key: 'origin',
      pivotable_label: 'area',
      pivotable_value: 'shipped_package_count',
    },
  };

  const perOrigin = {
    title: t('views.shipping.carrier.by_origin'),
    subtitle: carrier,
    id_key: 'origin',
    disableLabelFormatter: true,
    component: TableCard,
    ...(canUseOmsBackend ? omsPerOrigin : analyticsPerOrigin),
  };

  return [
    <BreadCrumb
      previousRoute="shipping"
      previousRouteLabel={t('menu.shipping')}
      key="breadcrumb"
    >
      {t('views.shipping.carrier.carrier')} :{' '}
      <span className="selectable">{carrier}</span>
    </BreadCrumb>,
    <div className="column is-full" key="per_source">
      <ContentCard {...props} {...perSource} />
    </div>,
    <div className="column is-full" key="per_origin">
      <ContentCard {...props} {...perOrigin} />
    </div>,
  ];
}
