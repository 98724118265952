import React from 'react';

export default function Loader(props) {
  const style = { height: props.height + 15, display: 'flex' };
  return (
    <div style={style}>
      <div className="loader"></div>
    </div>
  );
}
