const helpers = {
  csvSeparator: (language) => {
    if (language === 'fr-FR') {
      return ';';
    } else {
      return ',';
    }
  },
};

export default helpers;
