import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import chartColors from './chartColors.js';

import { useTranslation } from 'react-i18next';

import fr from 'apexcharts/dist/locales/fr.json';
import en from 'apexcharts/dist/locales/en.json';
import es from 'apexcharts/dist/locales/es.json';

import Formatter from '../../FormatFactory';
import sanitizeSeries from '../../sanitizeSeries';

import Helpers from './SBIChartHelpers';
import { useData } from '../../providers';

export default function CategoryChart(props) {
  const [nbItems] = useState(20);

  const { t, i18n } = useTranslation('translation');
  const { orderSources } = useData();

  const formatLabel = useCallback(
    (series) => {
      /*istanbul ignore if*/
      if (props.disableLabelFormatter) {
        return series;
      }
      return series.map((serie) => ({
        ...serie,
        data: serie.data.map((item) => {
          const source = orderSources.find((os) => os.name === item.x);
          return {
            ...item,
            x: source?.display_name || item.x,
          };
        }),
      }));
    },
    [orderSources, props.disableLabelFormatter]
  );

  function getAndSetSelection(evt, context, conf) {
    if (props.onChange) {
      const selectedIndexes = conf.selectedDataPoints;
      props.onChange(selectedIndexes);
    }
  }

  function interactivityStyler(event) {
    if (props.interactive) {
      event.srcElement.style.cursor = 'pointer';
    } else {
      event.srcElement.style.cursor = 'initial';
    }
  }

  const [state, setState] = useState({
    options: {
      chart: {
        id: props.title,
        zoom: {
          enabled: false,
        },
        locales: [fr, en, es],
        defaultLocale: i18n.language.substring(0, 2),
        events: {
          dataPointSelection: getAndSetSelection,
          dataPointMouseEnter: interactivityStyler,
        },
        toolbar: {
          tools: {
            download: "<img src='/assets/icons/download.svg' width='16px'/>",
          },
          export: {
            csv: {
              columnDelimiter: Helpers.csvSeparator(i18n.language),
            },
          },
        },
      },
    },
    series: [],
  });

  // Refresh when props are changing (i.e change in filters)
  useEffect(() => {
    setState((prev) => ({
      options: {
        chart: {
          ...prev.options.chart,
        },
        xaxis: {
          type: 'category',
          labels: {
            formatter: props.formatter || Formatter.noFormat,
          },
          tooltip: {
            enabled: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          formatter: props.formatter || Formatter.noFormat,
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: props.orientation === 'horizontal',
            endingShape: 'rounded',
          },
        },
        tooltip: {
          y: {
            formatter: props.formatter || Formatter.noFormat,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: props.orientation === 'horizontal',
            },
          },
          yaxis: {
            lines: {
              show: props.orientation !== 'horizontal',
            },
          },
        },
        colors: chartColors,
      },
      series: [
        {
          name: props.labels.value,
          data: sanitizeSeries(
            props.data.sort((a, b) => b.y - a.y).slice(0, nbItems)
          ),
        },
      ],
    }));
  }, [props, nbItems]);

  return (
    <div>
      <span className="chart-warning">
        {props.data.length > nbItems
          ? t('charts.size_warning', { number: nbItems })
          : ''}
      </span>
      <Chart
        options={state.options}
        series={formatLabel(state.series)}
        type={props.type}
        width="100%"
        height={props.height}
      />
    </div>
  );
}
