import React from 'react';
import TableCard from '../../ContentCard/TableCard';
import ContentCard from '../../ContentCard/ContentCard';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { noWrap } from '../../../DataTransformFactory';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import Formatter from '../../../FormatFactory';

export default function PerformancesSource(props) {
  const { t } = useTranslation('translation');
  const { source } = useParams();

  const best_selling = {
    title: t('views.performances.best_selling'),
    subtitle: source,
    columns: [
      { key: 'product_id', label: t('views.performances.cols.product_id') },
      { key: 'latest_sku', label: t('views.performances.cols.sku') },
      { key: 'latest_title', label: t('views.performances.cols.description') },
      {
        key: 'total_quantity',
        label: t('views.performances.cols.sold_qty'),
        formatter: Formatter.number,
      },
      {
        key: 'latest_stock',
        label: t('views.performances.cols.latest_stock'),
        formatter: Formatter.number,
      },
    ],
    id_key: 'product_id',
    api: {
      module: 'analytics',
      path: 'best_selling_products/table/v1/detailed/source_filtered',
      data_wrapper: noWrap,
      data_wrapper_opts: {
        data_key: 'data',
      },
      queryparams: {
        max_entry_count: 50,
        source: source,
      },
    },
    link: {
      route: '/performances/product/',
    },
    component: TableCard,
  };

  return [
    <BreadCrumb
      previousRoute="performances"
      previousRouteLabel={t('menu.performances')}
      key="breadcrumb"
    >
      {t('views.performances.source')} :{' '}
      <span className="selectable">{source}</span>
    </BreadCrumb>,
    <div className="column is-full" key="best_selling">
      <ContentCard {...props} {...best_selling} />
    </div>,
  ];
}
